import axios from 'axios';
import SystemHelper from './SystemHelper';

const DB_NAME = 'HabbieStorage';
const STORE_NAME = 'files';
const DB_VERSION = 1;

const FileHelper = {
    /**
     * Initialize IndexedDB
     */
    initDB: () => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(DB_NAME, DB_VERSION);
            
            request.onerror = () => reject(request.error);
            
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(STORE_NAME)) {
                    db.createObjectStore(STORE_NAME, { keyPath: 'path' });
                }
            };
            
            request.onsuccess = () => resolve(request.result);
        });
    },

    /**
     * Get video preferences for current platform
     * Returns object with 'extension', 'mime_type' and 'source_type'
     */
    getPlatformVideoPreferences() {
        let pref = { extension: '', mime_type: '', source_type: '' };
        if (window.device?.platform === 'iOS' || SystemHelper.isIosBrowser()) {
            pref.extension = 'm4v';
            pref.mime_type = 'video/x-m4v';
            pref.source_type = 'video/x-m4v';
        } else {
            pref.extension = 'webm';
            pref.mime_type = 'video/webm';
            pref.source_type = 'video/webm; codecs="vp9,opus"';
        }
        return pref;
    },

    /**
     * Get file from IndexedDB storage
     * @param {string} path - File path/key
     */
    get: async (path) => {
        const db = await FileHelper.initDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.get(path);

            request.onsuccess = () => {
                if (request.result) {
                    resolve({
                        file: request.result,
                        toURL: () => URL.createObjectURL(request.result.blob),
                        getMetadata: (callback) => callback({ size: request.result.blob.size })
                    });
                } else {
                    resolve(null);
                }
            };
            request.onerror = () => reject(request.error);
        });
    },

    /**
     * Get storage root path (maintained for compatibility)
     */
    getRootPath: () => {
        return Promise.resolve('/');
    },

    /**
     * Converts file to blob and returns URL
     * @param {string} path 
     * @param {string} mime 
     */
    async getLocalUrl(path, mime) {
        const file = await FileHelper.get(path);
        if (file) {
            return URL.createObjectURL(file.file.blob, { type: mime });
        }
        return null;
    },

    /**
     * Get content length from stored file
     */
    async getFileEntryLength(file) {
        return file.file.blob.size;
    },

    /**
     * Get content length from remote HEAD request
     */
    async getFileHeaderLength(path) {
        try {
            const response = await axios.head(path);
            return parseInt(response.headers['content-length']);
        } catch (error) {
            console.error('Error getting file header length:', error);
            return 0;
        }
    },

    /**
     * Get total content length in bytes
     */
    async getContentLength(paths) {
        let total = 0;
        for (const path of paths) {
            total += await FileHelper.getFileHeaderLength(path);
        }
        return total;
    },

    /**
     * Save remote file in IndexedDB storage
     */
    save: async (url, filename, onProgress) => {
        const db = await FileHelper.initDB();
        
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(url, {
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        if (onProgress) {
                            onProgress(progressEvent.loaded);
                        }
                    }
                });

                const transaction = db.transaction(STORE_NAME, 'readwrite');
                const store = transaction.objectStore(STORE_NAME);
                
                const fileData = {
                    path: filename,
                    blob: response.data,
                    timestamp: new Date().getTime()
                };

                const request = store.put(fileData);
                
                request.onsuccess = () => resolve();
                request.onerror = () => reject(request.error);
            } catch (error) {
                reject(error);
            }
        });
    },

    /**
     * Delete file from IndexedDB storage
     */
    delete: async (storage, filename) => {
        const db = await FileHelper.initDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.delete(filename);
            
            request.onsuccess = () => resolve();
            request.onerror = () => reject(request.error);
        });
    },

    /**
     * Get file as base64
     */
    getFileAsBase64: async (path, callback) => {
        const file = await FileHelper.get(path);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => callback(reader.result.split(',')[1]);
            reader.readAsDataURL(file.file.blob);
        } else {
            callback(null);
        }
    },

    /**
     * Convert base64 to Blob
     */
    b64toBlob: (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    },

    /**
     * Clear all files from IndexedDB storage
     */
    clearStorage: async () => {
        const db = await FileHelper.initDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.clear();
            
            request.onsuccess = () => {
                console.log('IndexedDB storage cleared');
                resolve();
            };
            request.onerror = () => reject(request.error);
        });
    },

    /**
     * Delete file (maintained for compatibility)
     */
    deleteFile: async (path_to_file_dir, filename) => {
        return FileHelper.delete(null, path_to_file_dir + filename);
    },

    /**
     * Preload file
     */
    preload: async (url, onProgress) => {
        const request = new Promise(async (resolve, reject) => {
            // Get response and progress
            await axios.get(url + '?' + new Date().getTime(), {
                accept: '*/*', 
                responseType: 'arraybuffer',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            }).then((response) => {
                // Check if file is found
                // Reject and abort if not
                if (response.status !== 200) {
                    reject('Error fetching file: ' + response.status);
                    return;
                } else {
                    // Update progress when file is downloaded
                    let content_length = parseInt(response.headers['content-length']);
                    onProgress(content_length);
                    // Resolve with file data
                    resolve(response.data);
                }
            });
            
        });
        return await request;
    },

    /**
     * Get duration of video in sec
     */
    getVideoDuration: async (url) => {
        const request = new Promise(async (resolve, reject) => {

            // Resolve on metadataloaded event
            let loaded = (event) => {
                resolve(event.target.duration);
            };

            let video = document.createElement('video');
            // Use event listener for Android (and browser)
            //if (!window.device || window.device.platform === 'Android') {
                video.addEventListener('loadedmetadata', loaded);
            /*}
            // Use interval for iOS
            else {
                let check_meta = null;
                let tries = 0;
                check_meta = setInterval(() => {
                    if (video.duration) {
                        clearInterval(check_meta);
                        resolve(video.duration);
                    } else {
                        console.log('no duration', video.duration);
                        tries++;
                        // Abort trying after 5 s
                        if (tries === 10) {
                            clearInterval(check_meta);
                            reject();
                        }
                    }
                }, 500);
            }
            */
            
            // Get video preferences for platform
            let video_pref = FileHelper.getPlatformVideoPreferences();

            await FileHelper.getLocalUrl(url, video_pref.mime_type).then((local_url) => {
                video.src = local_url;
                video.preload = 'metadata';
                video.onerror = (error) => {
                    console.error('Error loading local video: ' + local_url);
                    console.log(error);
                    reject(error);
                };
                video.load();
            });
        });

        return request;
    }
};

export default FileHelper;