import axios from 'axios';
import i18next from 'i18next';
//import Pusher from 'pusher-js';
import ApiHelper from './ApiHelper';
import SystemHelper from './SystemHelper';
import PushHelper from './PushHelper';
import FileHelper from './FileHelper';

const UserHelper = {

    authenticated: false,

    /**
     * Login user for the first time
     */
    login: (code, admin_token) => {
        return new Promise((resolve, reject) => {
            axios.post(ApiHelper.LoginHost + '/api/login', { code: code, admin_token: admin_token }).then(res => {
                console.log(res);
                resolve(res);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    },

    /**
     * Request validation token for admin
     */
    requestStaffCode: (code) => {
        return new Promise((resolve, reject) => {
            axios.post(ApiHelper.LoginHost + '/api/send-staff-code', { code: code }).then(res => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    /**
     * Verify validation token for admin
     */
    verifyStaffCode: (code, target_code) => {
        return new Promise((resolve, reject) => {
            axios.post(ApiHelper.LoginHost + '/api/verify-staff-code', { code: code, target_code: target_code }).then(res => {
                console.log(res);
                resolve(res);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    },

    /**
     * Get logged in user
     */
    getUser() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/user').then(async (user) => {
                if (user?.data?.data) {
                    // Set locale each time if set for user
                    if (user.data.data.meta.locale) {
                        await SystemHelper.storage.set('locale', user.data.data.meta.locale);
                        i18next.changeLanguage(user.data.data.meta.locale);
                    } else if (await SystemHelper.storage.get('locale') === 'undefined') {
                        await SystemHelper.storage.remove('locale');
                    }
                } else {
                    console.error('No user data');
                    reject('No user data');
                }

                resolve(user.data.data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    /**
     * Logout user
     * @param {Component instance for redirecting to login} component
     */
    async logout(history, unsubscribe) {

        console.log('Logging out, unsubcribe:', unsubscribe);

        // Remove device id if exists
        const device_id = await SystemHelper.storage.get('device_id');
        if (device_id) {
            await PushHelper.removeDevice(device_id);
        }

        if (unsubscribe) {
            // Unsubscribe to meeting listener
            unsubscribe();
        }
        // TODO: Move this to provider and find a way to access it here
        //const userUuid = await SystemHelper.storage.get('user_uuid');
        //const pusher = new Pusher('9e2dfdb329be9e1384bb', { cluster: 'eu' });
        //pusher.unsubscribe('meeting-call-' + userUuid);
        //console.log('Unsubscribed to meeting-call-' + userUuid);

        // Logout backend
        //ApiHelper.Post('/logout')

        // Logout admin from device
        await UserHelper.logoutAdmin();

        // Remove locale storage objects
        await SystemHelper.storage.removeMany([
            'token',
            'user_uuid',
            'host',
            'user_id',
            'user_name',
            'last_workout',
            'workout_uuid',
            'program_uuid',
            'current_exercise_index',
            'locale',
            'video_setting',
        ]);

        // Clear all files from IndexedDB storage
        await FileHelper.clearStorage();

        console.log('Removed all media files and cleared IndexedDB');

        // Send to login if history sent
        if (history) {
            console.log('redirecting...', history);
            history.push('/login');
        } else {
            console.log('No history, using window.location...', history);
            window.location.href = '/#login';
            window.location.reload();
        }
    },

    /**
     * Logout current admin
     */
    async logoutAdmin() {
        await SystemHelper.storage.remove('admin_token');
		await SystemHelper.storage.remove('host');
    },

    token: {
        set: async (token) => {
            //await SystemHelper.storage.set('token', token);
            console.log('Saving token...')
            await SystemHelper.storage.set('token', token);
        },
        get: async () => {
            //return await SystemHelper.storage.get('token');
            return await SystemHelper.storage.get('token');
        }
    },

    code: {
        set: async (code) => {
            await SystemHelper.storage.set('user_id', code);
        },
        get: async () => {
            return await SystemHelper.storage.get('user_id');
        }
    },

    name: {
        set: async (name) => {
            await SystemHelper.storage.set('user_name', name);
        },
        get: async () => {
            return await SystemHelper.storage.get('user_name');
        }
    },

    /**
     * Returns programs that run today
     * @param {All user's programs} all_programs
     */
    getTodaysPrograms(all_programs) {
        const now = new Date();
        const day_of_week = SystemHelper.dayOfWeek(now.getDay());
        let programs = all_programs.data.filter((x) => { return (x.meta.days.indexOf(day_of_week) > -1) });
        return programs;
    },

    /**
     * Checks if program is active due to start and end date
     * @param {The program to check} program 
     * @returns Bool indicating if program is active
     */
    isProgramActive(program) {
        let now = new Date().toISOString().substring(0, 10);
        let starts_at = new Date(program.starts_at);
        let ends_at = new Date(program.ends_at);

        // Only use the date part
        now = new Date(now);

        return (
            (now >= starts_at || !program.starts_at) && 
            (now <= ends_at || !program.ends_at)
        );
    },

    /**
     * Get current active program for user
     */
    getUserProgram() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/program-active').then(program => {
                let active_program = program.data;
                if (active_program && active_program.length === 0) {
                    active_program = null;
                    console.log('No active program');
                }
                resolve(active_program);

            }).catch(error => {
                //alert(error);
                reject(error);
            });
        });
    },

    /**
     * Get the next reminder
     */
    getNextReminder() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/reminders/next').then(data => {
                console.log('remmdmdmd', data);
                resolve(data.data.reminder);
            }).catch(error => {
                reject(error);
            });
        });
    }
};

export default UserHelper;
